<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Добавить ребенка
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSidebar"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Фио ребенка"
            rules="required"
          >
            <b-form-group
              label="Фио"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="userData.full_name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Фио ребенка"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Full Name Father -->
          <b-form-group
            label="Фио отца"
            label-for="full-name-father"
          >
            <b-form-input
              id="full-name-father"
              v-model="userData.full_name_father"
              autofocus
              trim
              placeholder="Фио отца"
            />

          </b-form-group>

          <!-- Full Name Mother -->
          <b-form-group
            label="Фио матери"
            label-for="full-name-mother"
          >
            <b-form-input
              id="full-name-mother"
              v-model="userData.full_name_mother"
              autofocus
              trim
              placeholder="Фио матери"
            />

          </b-form-group>

          <!-- Phone Father -->
          <b-form-group
            label="Телефон отца"
            label-for="phone-father"
          >
            <b-form-input
              id="phone-father"
              v-model="userData.phone_number_father"
              autofocus
              trim
              placeholder="Телефон отца"
            />

          </b-form-group>

          <!-- Phone Mother -->
          <b-form-group
            label="Телефон матери"
            label-for="phone-mother"
          >
            <b-form-input
              id="phone-mother"
              v-model="userData.phone_number_mother"
              autofocus
              trim
              placeholder="Телефон матери"
            />

          </b-form-group>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
          >
            <b-form-group
              label="Основной Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.first_email"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Основной Email"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
          >
            <b-form-group
              label="Запасной Email"
              label-for="second-email"
            >
              <b-form-input
                id="second-email"
                v-model="userData.second_email"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Запасной Email"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="дата рождения"
            :rules="
              {regex:validDateRegex , required:true}"
          >
            <b-form-input
              id="birthday"
              v-model="userData.birth_date"
              v-mask="'####-##-##'"
              placeholder="гггг.мм.дд"
              :state="getValidationState(validationContext)"
              trim
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

          <b-form-group
            label="Группа"
            label-for="childrenGroup"
          >
            <b-form-select
              id="childrenGroup"
              v-model="userData.children_group"
              label="Группа"
              :options="option"
            />
          </b-form-group>

          <b-form-group
            label="Секция"
            label-for="childrenSection"
          >
            <b-form-select
              id="childrenSection"
              v-model="userData.child_section"
              label="Группа"
              value-field="id"
              text-field="name"
              multiple
              :options="sectionOptions"
            />
          </b-form-group>

          <b-form-checkbox
            id="excludeFromEmail"
            v-model="userData.exclude_from_email"
            value="yes"
            unchecked-value="no"
          >Исключить из ежемесячной рассылки комментариев</b-form-checkbox>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Сохранить
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Отмена
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormSelect,
  BFormCheckbox,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, watch } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import Vue from 'vue'
import { validDateRegex } from '@/libs/regexes'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
    BFormCheckbox,
    flatPickr,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      option: [],
      sectionOptions: [],
      validDateRegex
    }
  },
  created() {
    this.$store.dispatch('info/getSections')
      .then(() => {
        this.sectionOptions = this.$store.state.info.sections
      })
    this.$store.dispatch('info/getChildrenGroups')
      .then(() => {
        this.$store.state.info.childrenGroups.forEach(group => {
          this.option.push({
            text: group.name,
            value: group.id
          })
        })
      })
  },
  setup(props, { emit }) {
    const blankUserData = {
      full_name: '',
      full_name_father: '',
      full_name_mother: '',
      phone_number_father: '',
      phone_number_mother: '',
      first_email: '',
      second_email: '',
      birth_date: '',
      children_group: '',
      child_section: [],
      exclude_from_email: 'no',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
      store.commit('info/emptyChildToEdit')
    }
    const hideSidebar = () => {
      emit('update:is-add-new-user-sidebar-active', false)
      userData.value = JSON.parse(JSON.stringify(blankUserData))
      store.commit('info/emptyChildToEdit')
    }
    watch(() => store.state.info.childToEdit, child => {
      if (child && child.hasOwnProperty('id')) {
        userData.value.id = child.id
        userData.value.full_name = child.full_name
        userData.value.full_name_father = child.full_name_father
        userData.value.full_name_mother = child.full_name_mother
        userData.value.phone_number_father = child.phone_number_father
        userData.value.phone_number_mother = child.phone_number_mother
        userData.value.first_email = child.first_email
        userData.value.second_email = child.second_email
        userData.value.birth_date = child.birth_date
        userData.value.children_group = child.children_group
        userData.value.child_section = child.child_section
        userData.value.exclude_from_email = child.exclude_from_email ? 'yes' : 'no'
      }
    })
    watch(() => props.isAddNewUserSidebarActive, val => {
      if (val === false) {
        userData.value = JSON.parse(JSON.stringify(blankUserData))
      }
    })

    const onSubmit = () => {
      if (userData.value.hasOwnProperty('id')) {
        store.dispatch('info/editChild', userData.value)
          .then(() => {
            Vue.$toast.success('Сохранён', { timeout: 2000 })
            emit('refetch-data')
            emit('update:is-add-new-user-sidebar-active', false)
            userData.value = blankUserData
          }).catch(() => {
            Vue.$toast.error('Не удалось сохранить', { timeout: 2000 })
          })
      } else {
        store.dispatch('info/addChildren', userData.value)
          .then(() => {
            Vue.$toast.success('Сохранён', { timeout: 2000 })
            emit('refetch-data')
            emit('update:is-add-new-user-sidebar-active', false)
            userData.value = blankUserData
          }).catch(() => {
            Vue.$toast.error('Не удалось сохранить', { timeout: 2000 })
          })
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,
      hideSidebar,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
