<template>
  <div>
    <b-button :variant="this.date.monthIndex ? 'primary' : 'secondary '"
              style="margin-left: 5px; height: 100%;" @click="showMonthPicker = !showMonthPicker"
    >
      {{ this.date.month || 'Выбрать месяц' }}
    </b-button>
    <month-picker :default-month="date.monthIndex || defaultMonth" v-if="showMonthPicker"
                  :show-year="false" lang="ru" @input="onInput" class="month-picker-component"
    ></month-picker>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { MonthPicker } from 'vue-month-picker'

export default {
  components: {
    BButton,
    MonthPicker
  },
  data() {
    return {
      showMonthPicker: false,
      date: {
      }
    }
  },
  props: {
    defaultMonth: { default: 1, type: Number }
  },
  methods: {
    onInput(date) {
      this.date = date
      this.showMonthPicker = false
      this.$emit('input', date)
    }
  }
}

</script>

<style lang="scss">
.month-picker-component {
  z-index: 10;
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);

  .month-picker .month-picker__month.selected {
    background-color: var(--purple);
  }
}
</style>
