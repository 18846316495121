<template>
  <div>
    <b-modal
      id="modal-tall"
      v-model="childReportModalShow"
      :title="'Отчет ' + reportData.children"
      @change="(val) => $emit('update:child-report-modal-show', val)"
    >
      <b-card class="text-center">
        <b-card-text>Кол-во посещений садика
          {{ reportData.stats.count_visits }}</b-card-text>
        <b-card-text>Посещения секций
          {{ reportData.stats.count_additional_visits }}</b-card-text>
      </b-card>
      <div style="margin-top: 25px">
        <b-form-textarea
          id="textarea"
          v-model="reportData.header"
          placeholder="Шапка сообщения"
          rows="3"
          max-rows="6"
          spellcheck="true"
        />
      </div>
      <div
        v-for="review in reportData.reviews"
        :key="review.id"
        style="margin-top: 25px"
      >
        {{ review.section_name }}
        <b-form-textarea
          id="textarea"
          v-model="review.description"
          placeholder="Enter something..."
          rows="3"
          max-rows="6"
        />
      </div>

      <div style="margin-top: 25px">
        <b-form-textarea
          id="textarea"
          v-model="reportData.about"
          placeholder="Общий комментарий"
          rows="3"
          max-rows="6"
          spellcheck="true"
        />
      </div>

      <month-picker-button
        class="mt-2"
        :default-month="+(new Date().getMonth()) + 1"
        @input="onMonthInput"
      />

      <hr>
      <div class="mt-1">
        <fieldset>
          <legend>Прикрепить файл</legend>
          <b-form-file
            v-model="reportData.file"
            :state="Boolean(file)"
            required="false"
            placeholder="Переместите сюда файл, или нажмите кнопку Browse"
            drop-placeholder="Переместите сюда файл..."
          />
        </fieldset>
      </div>

      <template #modal-footer="{}">
        <b-button
          size="lg"
          variant="btn btn-primary"
          @click="sendReport"
        >
          Отправить
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BFormTextarea,
  BCard,
  BCardText,
  BFormFile,
} from "bootstrap-vue"
import Vue from "vue"
import { ref, watch } from "@vue/composition-api"
import store from "@/store"
import MonthPickerButton from '@/views/components/MonthPickerButton.vue'

export default {
  components: {
    BButton,
    BModal,
    BFormTextarea,
    BCard,
    BCardText,
    BFormFile,
    MonthPickerButton,
  },

  model: {
    prop: "childReportModalShow",
    event: "update:child-report-modal-show",
  },

  methods: {
    onMonthInput(date) {
      this.$emit('update:month', date.monthIndex)
      this.reportData.month = date.monthIndex
    },
  },

  props: {
    childReportModalShow: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const blankReportData = {
      child_id: null,
      children: null,
      stats: {},
      reviews: [],
      about: null,
      header: null,
      month: null,
      file: null,
    }

    const reportData = ref(JSON.parse(JSON.stringify(blankReportData)))
    watch(
      () => store.state.info.reportEdit,
      (report) => {
        if (report) {
          reportData.value = report
        }
      }
    )

    watch(
      () => props.childReportModalShow,
      (val) => {
        if (val === false) {
          reportData.value = JSON.parse(JSON.stringify(blankReportData))
        }
      }
    )

    const sendReport = () => {
      store
        .dispatch("info/sendReport", reportData.value)
        .then(() => {
          Vue.$toast.success("Отчет отправлен", { timeout: 2000 })
          emit('update:child-report-modal-show', false)
        })
        .catch(() => {
          Vue.$toast.error("Не удалось отправить отчет", { timeout: 2000 })
        })
    };

    return {
      reportData,
      sendReport,
    }
  },
}
</script>

<style>
</style>
