<template>
  <div>
    <list-children-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
    />

    <child-report
      :child-report-modal-show.sync="childReportModalShow"
      @update:month="updateReport"
    />

    <b-row>
      <b-col
        cols="4"
        class="mb-1"
      >
        <b-form-group
          label="Фильтр"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0 d-flex align-items-center"
          style="font-size: 30px;"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Поиск"
              @input="filterData"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''; filterData()"
              >
                Очистить
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col
        cols="4"
        class="mb-1"
      >
        <b-input-group-append>
          <b-button
            :variant="bDayActive ? 'primary' : 'secondary '"
            @click="bDays()"
          >
            Ближайшие дни рождения
          </b-button>
          <div style="position: relative">

            <month-picker-button
              v-if="bDayActive"
              @input="changeDate"
            />
          </div>

          <b-button
            v-if="bDayActive"
            style="margin-left: 5px"
            @click="print()"
          >
            Распечатать
          </b-button>
        </b-input-group-append>
      </b-col>

      <b-col
        v-if="$can('create', 'child')"
        cols="2"
        class="mb-1"
      >
        <b-button
          variant="primary"
          @click="isAddNewUserSidebarActive = true"
        >
          <span class="text-nowrap">Добавить ребенка</span>
        </b-button>
      </b-col>
      <b-col cols="12">
        <b-table-simple caption-top>
          <b-thead>
            <b-tr>
              <b-th>&nbsp;</b-th>
              <b-th>#</b-th>
              <b-th>Ребёнок</b-th>
              <b-th>ДР</b-th>
              <b-th>Мама</b-th>
              <b-th>Номер мамы</b-th>
              <b-th>Папа</b-th>
              <b-th>Номер папы</b-th>
              <b-th>&nbsp;</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <template v-for="(group, groupId) in groupedChildren">
              <template v-for="(child, index) in group.children">
                <b-tr :key="groupId + index">
                  <b-td
                    v-if="index == 0"
                    :rowspan="group.children.length"
                  >{{ group.name }} {{ group.year }}</b-td>
                  <b-td>{{ index + 1 }}</b-td>
                  <b-td>{{ child.full_name }}</b-td>
                  <b-td>{{ child.birth_date }}</b-td>
                  <b-td>{{ child.full_name_mother }}</b-td>
                  <b-td>{{ child.phone_number_mother }}</b-td>
                  <b-td>{{ child.full_name_father }}</b-td>
                  <b-td>{{ child.phone_number_father }}</b-td>
                  <b-td>
                    <div class="d-flex justify-content-around actions-btns h4">
                      <b-icon-pencil-fill
                        class="cursor-pointer"
                        @click="editSidebar(child)"
                      />

                      <b-icon-file-earmark-fill
                        class="cursor-pointer"
                        @click="getReport(child)"
                      />
                      <b-icon-person-x-fill
                        class="cursor-pointer"
                        @click="deleteChild(child)"
                      />
                    </div>
                  </b-td>
                </b-tr>
              </template>
            </template>
          </b-tbody>
        </b-table-simple>
        <!-- <b-table
          :fields="fields"
          :items="items"
          responsive
          show-empty
          empty-text="Нет материалов"
          class="overflow-x-scroll"
          @row-selected="onRowSelected"
        >
          <template #cell(actions)="row">
            <div class="d-flex justify-content-around actions-btns h4">
              <b-icon-pencil-fill
                class="cursor-pointer"
                @click="editSidebar(row.item)"
              />

              <b-icon-file-earmark-fill
                class="cursor-pointer"
                @click="getReport(row.item)"
              />
              <b-icon-person-x-fill
                class="cursor-pointer"
                @click="deleteChild(row.item)"
              />
            </div>
          </template>
        </b-table> -->
      </b-col>

      <b-col
        id="childs_bday"
        cols="12"
      >
        <b-table
          :fields="fieldsForPrint"
          :items="itemsForPrint"
          responsive
          show-empty
          bordered
          empty-text="Нет материалов"
          @row-selected="onRowSelected"
        >
          <template #cell(birth_date)="row">
            <span v-html="row.item.birth_date" />
          </template>
          <template #cell(1) />
          <template #cell(2) />
          <template #cell(3) />
          <template #cell(4) />
          <template #cell(5) />
          <template #cell(6) />
          <template #cell(7) />
          <template #cell(8) />
        </b-table>
      </b-col>

      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          class="mt-1"
          align="right"
          :total-rows="totalCount"
          :per-page="limit"
          @change="pageChanged"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BPagination,
  BRow,
  BTable,
  BTableSimple,
  BThead, BTbody,
  BTr, BTh, BTd,
  BIconPencilFill,
  BIconX,
  BIconFileEarmarkFill,
  BIconPersonXFill,
  BIconPersonFillX,
} from 'bootstrap-vue'
import { MonthPicker } from 'vue-month-picker'
import MonthPickerButton from '@/views/components/MonthPickerButton'
import ListChildrenAddNew from './ListChildrenAddNew.vue'
import ChildReport from './ChildReport.vue'

export default {
  components: {
    MonthPickerButton,
    MonthPicker,
    BButton,
    BLink,
    BTable,
    BTableSimple,
    BThead, BTbody, BTr, BTh, BTd,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BPagination,
    BRow,
    BCol,
    ListChildrenAddNew,
    BIconPencilFill,
    BIconFileEarmarkFill,
    BIconPersonFillX,
    BIconPersonXFill,
    BIconX,
    ChildReport,
  },
  data() {
    return {
      date: {
        month: null,
        monthIndex: null,
      },
      chosenChild: null,
      fieldsForPrint: [
        {
          key: 'full_name',
          label: 'ФИО Ребенка',
        },
        {
          key: 'birth_date',
          label: 'Дата',
        },
        {
          key: '1',
          label: 'Герой (2000р.) +20% если аниматор',
        },
        {
          key: '2',
          label: 'Фотограф 1500р',
        },
        {
          key: '3',
          label: 'Офрмление шарики 60р',
        },
        {
          key: '4',
          label: 'Подарки детям',
        },
        {
          key: '5',
          label: 'Подарок имениннику',
        },
        {
          key: '6',
          label: 'К оплате итого:',
        },
        {
          key: '7',
          label: 'Кто игрет',
        },
        {
          key: '8',
          label: 'Дарили ребятам',
        },
      ],

      fields: [
        {
          key: 'id',
          label: 'Номер',
        },
        {
          key: 'full_name',
          label: 'ФИО Ребенка',
        },
        {
          key: 'birth_date',
          label: 'День рождения',
        },
        {
          key: 'group_name',
          label: 'Название группы',
        },
        {
          key: 'full_name_father',
          label: 'ФИО отца',
        },
        {
          key: 'phone_number_father',
          label: 'Номер отца',
        },
        {
          key: 'full_name_mother',
          label: 'ФИО матери',
        },
        {
          key: 'phone_number_mother',
          label: 'Номер матери',
        },
        {
          key: 'first_email',
          label: 'Email',
        },
        {
          key: 'actions',
          label: 'Действия',
        },
      ],
      isAddNewUserSidebarActive: false,
      childReportModalShow: false,
      bDayActive: false,
      filter: '',
      selected: [],
      currentPage: 1,
      limit: 50,
      wDays: [
        'Воскресенье',
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота',
      ],
      itemsForPrint: '',
    }
  },
  computed: {
    items() {
      // console.log({ items: this.$store.state.info.childrenList })
      return this.$store.state.info.childrenList
    },
    totalCount() {
      return this.$store.state.info.childrenCount
    },
    groupedChildren() {
      const cl = this.$store.state.info.childrenList
      const childrenGroups = {}
      cl.forEach(
        ch => {
          const groupId = ch.children_group
          if (groupId in childrenGroups) {
            childrenGroups[groupId].children.push(ch)
          } else {
            childrenGroups[groupId] = {
              name: ch.group_name,
              year: ch.group_year,
              children: [ch],
            }
          }
        }
      )
      return childrenGroups
    },
    groups() {
      const cl = this.$store.state.info.childrenList
      const childrenGroups = {}
      cl.forEach(
        ch => {
          const groupId = ch.children_group
          if (groupId in childrenGroups) {
            childrenGroups[groupId].children.push(ch)
          } else {
            childrenGroups[groupId] = {
              name: ch.group_name,
              year: ch.group_year,
              children: [ch],
            }
          }
        }
      )
      return childrenGroups.keys().map(
        k => ({
          id: k, name: childrenGroups[k].name + childrenGroups[k].year
        })
      )
    }
  },
  created() {
    this.fetchData(1)
  },
  methods: {
    changeDate(date) {
      this.date = date
      this.bDays(this.date.monthIndex)
      console.log({ tdate: this.date.monthIndex })
    },
    async print() {
      await this.$htmlToPaper('childs_bday')
    },
    editSidebar(child) {
      this.$store.commit('info/setChildToEdit', child)
      this.isAddNewUserSidebarActive = true
    },
    getReport(child) {
      this.chosenChild = child
      const params = { child_id: child.id }
      this.$store.dispatch('info/getReport', params)
      this.childReportModalShow = true
    },
    deleteChild(child) {
      this.chosenChild = child
      this.$store
        .dispatch('info/deleteChild', child.id)
        .then(() => {
          this.$toast.success('Ребенок удален из базы')
        })
        .catch(() => {
          this.$toast.error('Не удалось удалить ребенка из базы')
        })
    },
    updateReport(monthIndex) {
      const params = {
        child_id: this.chosenChild.id,
        month: monthIndex,
      }
      this.$store.dispatch('info/getReport', params)
    },
    fetchData(page) {
      const params = {
        limit: this.limit,
        offset: page === 1 ? 0 : page * this.limit - this.limit,
        children_group__archived: 'False',
      }
      if (this.filter !== '') {
        params.search = this.filter
      } else {
        delete params.search
      }
      this.$store.dispatch('info/getChildren', params)
    },
    filterData() {
      this.fetchData(1)
    },
    pageChanged(page) {
      this.fetchData(page)
    },
    onRowSelected(items) {
      this.$emit('items-selected', items)
    },

    async bDays(monthIndex) {
      if (!this.bDayActive || monthIndex) {
        console.log('get date')
        await this.$store.dispatch('info/getChildBDays', { month: monthIndex })
        this.itemsForPrint = JSON.parse(
          JSON.stringify(this.$store.state.info.childrenList),
        )
        this.itemsForPrint.forEach(child => {
          const d = new Date(child.birth_date)
          child.birth_date += `<br/>${this.wDays[d.getDay()]}`
          if (d.getDay() == 0) {
            const moveToNextDay = new Date()
            moveToNextDay.setDate(d.getDate() + 1)
            child.birth_date += `<br/> перенесено на <br/>${moveToNextDay.getDate()}-e ${this.wDays[moveToNextDay.getDay()]}`
          }
        })
      } else {
        this.fetchData(1)
      }
      if (!monthIndex) {
        this.bDayActive = !this.bDayActive
        this.date = {}
      }
    },
  },
}
</script>

<style lang="scss">
.actions-btns {
  & > svg {
    margin: 0 3px;
  }
}
.table-custom-active {
  background-color: #161d31;
}

#childs_bday {
  visibility: hidden;
}

tr[role=row] td:nth-child(2) {
  white-space: nowrap;
}

</style>
